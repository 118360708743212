import { createSlice } from '@reduxjs/toolkit'
import { videoIMGDefault } from '../../config';

const videoState = createSlice({
  name: 'videoState',
  initialState: {
	ao_vivo:false,
	src:videoIMGDefault,
	chat:false,
	videoAtual:0,
	urls:[]
  },
  reducers: {
    changeVideo: (state,action:any) => {
		const {urls} = action.payload;
		state.urls = urls;
	},
	changeIdioma: (state,action:any) => {
		const {videoAtual} = action.payload;
		state.videoAtual = videoAtual;
	},
	changeChat: (state,action:any) => {
		const {chat} = action.payload;
		if(typeof chat !== "boolean") return;
		state.chat=chat;
	},
	changeAoVivo: (state,action:any) => {
		const {ao_vivo} = action.payload;
		state.ao_vivo=ao_vivo;
	},
  }
})

export const getVideoState = (state:any) => state[videoState.name]
export const video = (state:any) => state[videoState.name].src

export default videoState
