import { createSlice } from '@reduxjs/toolkit'

const agendaState = createSlice({
  name: 'agendaState',
  initialState: {
	dados:[],
	ao_vivo:false
  },
  reducers: {
    changeAgenda: (state,action:any) => {
		const agenda = action.payload;
		state.dados=agenda;
		if(Array.isArray(agenda)){
			state.ao_vivo = !!agenda.find(val => val.ao_vivo === "1");
		}else{
			state.ao_vivo = false;
		}
	},
  }
})

export const getAgendaState = (state:any) => state[agendaState.name]

export default agendaState
