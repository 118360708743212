import React from 'react'
import { useHistory } from 'react-router-dom';
import styled from 'styled-components'
import { Movimento } from '../../assets/Images'

export default function NavbarDesktop() {
	const history = useHistory();
	return (
		<Container>
			<Col1>
				<Movimento />
			</Col1>
			<Col2>
				<div onClick={() => {history.push('/home#home')}}>Home</div>
				<div onClick={() => {history.push('/home#agenda')}}>Agenda e Speakers</div>
				{/* <div onClick={() => {history.push('/home#speaker')}}>Speakers</div> */}
				
			</Col2>
		</Container>
	)
}

const Container = styled.div`
	background-color:${props => props.theme.yellow};
	width:100%;
	min-width:100%;
	height:130px;
	min-height:130px;
	position:sticky;
	display:flex;
	flex-direction:row;
	top: 0;
	z-index:99;
	@media (max-width: 950px) {
		display:none;
	}
`

const Col1 = styled.div`
	width:50%;
	min-width:50%;
	align-items:center;
	justify-content:center;
	text-align: center;
	& img{
		width:180px;
		height:auto;
	}
`
const Col2 = styled.div`
	color:${props => props.theme.purple};
	font-size:22px;
	font-weight:500;
	display:flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	
	& div{
		cursor: pointer;
		text-transform: uppercase;
		padding-right: 15px;
		padding-left: 15px;
		border-right:0.2em solid ${props => props.theme.purple};
	}
	& div:last-child{
		border-right:none;
	}
`