import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Solomon', 'Roboto', 'sans-serif';
    -webkit-tap-highlight-color: transparent;
  }
  *:focus{
    outline:none;
  }
  html{

  }
  body{
	min-height: 350px;
  }
  #root{
	display:flex;
	flex-direction: column;
	min-height: 100vh;
	height:100%;
	flex-grow:1;
	background-color:#fde000;
  }
  input::placeholder{
    color: ${props => props.theme.placeholder};
  }
`
