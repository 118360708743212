import React, { useState } from 'react'
import styled from 'styled-components'
import { Movimento } from '../../assets/Images'
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { login } from '../../services/apiCalls';
import Modal from '../../components/Modal/Modal';
import Termos from './Termos';


export default function Cadastro() {
	const [email,setEmail]:any = useState("");
	const [error,setError] = useState('');
	const [errorTermos,setErrorTermos] = useState('');
	const [aceitoTermos,setAceitoTermos] = useState(false);
	const [modalOpen,setModalOpen] = useState(false);


	const history = useHistory();
	const enterAction = (e:any) => {
		if (e.key === 'Enter') {
			doLogin();
		}
	}

	async function doLogin(){
		setError('');
		setErrorTermos('');
		if(!aceitoTermos){
			setErrorTermos("É necessário aceitar os termos");
			return false;
		}
		if(!email.includes('@')){
			setError("E-mail inválido")
			return false;
		}
		const res = await login(email);
		if(!res){
			setError("E-mail inválido")
		}
		if(res){
			if(res.status ===1){
				if(res.data && res.data.register_data == "1"){
					history.push('/home');
				}else{
					history.push('/cadastro');
				}
			}else{
				setError("E-mail inválido")
			}
		}
	}
	

	return (
		<>
			<Modal open={modalOpen}>
				<Termos confirmar={() => {setModalOpen(false) }} />
			</Modal>
			<Logo />
			<Footer>
				<div>Galliprant™, Elanco e o logotipo de barras diagonais são marcas registradas da Elanco ou de suas filiais. Todos os direitos reservados. Julho/2021. PM-BR-21-0483</div>
			</Footer>
			<Container>
				<Content>
					<Movimento />
					
					<Titulo style={{marginTop:'auto'}}>Faça seu cadastro</Titulo>
					<div style={{height:30}} />
					<Input style={{marginBottom:5}} onKeyDown={enterAction} value={email} onChange={(e) => setEmail(e.target.value)} placeholder="E-mail" />
					{error && <ErrorMessage><div>{error}</div></ErrorMessage>}
					<div style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center', fontSize:18 }}><GreenCheckbox
							checked={aceitoTermos}
							onChange={(e:any) => setAceitoTermos(!!e.target.checked)}
							name="check-termos"
						/><div>Aceito os{' '} <span onClick={() => setModalOpen(true)} style={{paddingLeft:5, paddingRight:5, cursor:'pointer', textDecoration: 'underline', fontWeight:400}}>Termos e Politica de Privacidade</span> do Site</div></div>
					{errorTermos && <ErrorMessage><div>{errorTermos}</div></ErrorMessage>}
					<Button onClick={doLogin}><div>CONFIRMAR</div></Button>
				</Content>
			</Container>
		</>
	)
}

const CheckBoxContainer = styled.div`
	display:flex;
	flex-direction:row;
	align-items:center;
`
const ErrorMessage = styled.div`
	font-size:20px;
	color:#bf0c3d;
	font-weight:500;
`

const GreenCheckbox = withStyles({
	root: {
	  color: '#0071cb',
	  '&$checked': {
		color: '#0071cb',
	  },
	},
	checked: {},
  })((props:any) => <Checkbox color="default" {...props} />);
  

const Texto = styled.div`
	color:black;
	text-align:center;
	font-size:18px;
	text-transform:uppercase;
`

const Content = styled.div`

	display:flex;
	align-items:center;
	max-width:500px;
	width:500px;
	min-width:500px;
	height:500px;
	margin-left:10%;
	flex-direction:column;
	

	& .movimento {
		width: 239px;
	    height: 153px;
	}

	@media (max-width: 1100px) {
		height:100%;
	}

	@media (max-width: 600px) {
		max-width: 90%;
		width: 90%;
		min-width:90%;
		margin-left:0%;
		height:100%;
	}
`
const Titulo = styled.div`
	font-size:20px;
	color:#0071cb;
	font-weight:500;
	margin-top:auto;
`

const Input = styled.input`
	
	background-color:white;
	height:70px;
	text-align:center;
	width:100%;
	color:#0071cb;
	border:0px;
	font-size:20px;
	font-weight:400;

	::placeholder{
		color:#0071cb;
	}
`

const Button = styled.div`
	height:70px;
	text-align:center;
	width:100%;
	color:#0071cb;
	border:0px;
	font-size:16px;
	font-weight:400;
	text-align:center;
	text-transform:uppercase;
	font-weight:500;
	color:white;
	margin-top:10px;
	display:flex;
	align-items:center;
	justify-content:center;
	cursor:pointer;
	background-color:${props => props.theme.purple};
`

const Container = styled.div`
	min-width:250px;
	max-width:100%;
	max-height:100%;
	width:100vw;
	height:100vh;
	background-image:url('assets/cadastro_fundo.jpg');
	background-size:cover;
	background-position:center;
	display:flex;
	align-items:center;
	min-height:650px;

	@media (max-width: 1100px) {
		padding-top:10px;
		padding-bottom:150px;
		min-height:650px;
	}

	@media (max-width: 600px) {
		padding-top:0px;
		padding-bottom:150px;
		justify-content:center;
		min-height:600px;
		align-items:flex-end;
		min-height:650px;
	}

`


const Footer = styled.div`
	display:flex;
	align-items:center;
	font-size:10px;
	padding-left:20px;
	color:white;
	background-color:#c6043d;
	height:50px;
	width:100%;
	position:fixed;
	bottom:0;
	left:0;
	z-index:999;
`

const Logo = styled.div`
	position: fixed;
	background-image:url('assets/galliprant.png');
	right:0;
    height: 100vh;
	pointer-events: none;
    width: 40%;
	max-width: 550px;
	background-repeat:no-repeat;
    background-position-y: bottom;
	background-size:contain;
	z-index:9;
	bottom:50px;
	min-width:200px;
	width: 30%;
	max-width:calc(100vh/1.4);
`