import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { enviePergunta } from '../../services/apiCalls'

export default function FacaSuaPergunta({closeModal,modalStatus}:any) {
	const [pergunta,setPergunta] = useState('')
	const [response,setResponse] = useState('')
	const [error,setError] = useState('')
	const confirmar = async () => {
		if(pergunta.length < 10){
			setError("Por favor, elabore um pouco mais a pergunta");
			return;
		}
		setResponse('Enviando pergunta')
		const res = await enviePergunta(pergunta);
		if(res !== null){
			setResponse('Pergunta enviada')
			closeModal();
		}else{
			setResponse('Ocorreu um erro, tente mais tarde')
		}
	}
	useEffect(() => {
		if(modalStatus === true){
			setResponse('')
			setPergunta('')
		}
	}, [modalStatus])
	return (
		<Container>
		<FacaSuaPerguntaContainer>
		    {!response && <EnviePerguntaTitulo>Envie Sua Pergunta</EnviePerguntaTitulo>}
			{!response && <EnviePerguntaTextArea value={pergunta} onChange={(e) => setPergunta(e.target.value)} placeholder="Digite aqui"></EnviePerguntaTextArea>}
			{!response && error && <ErrorMessage><div>{error}</div></ErrorMessage>}
			{!response && <EnviePerguntaButton onClick={confirmar}><div>Enviar</div></EnviePerguntaButton>}
			{response && <EnviePerguntaTitulo>{response}</EnviePerguntaTitulo>}
			<EnviePerguntaButton onClick={closeModal}><div>Voltar</div></EnviePerguntaButton>
		</FacaSuaPerguntaContainer>
		</Container>
	)
}

const ErrorMessage = styled.div`
    margin-top:10px;
    font-size:20px;
	color:#bf0c3d;
	font-weight:500;
	background-color:#FFFFFF99;
	padding:8px;
	text-align:center;
`

const Container = styled.div`
	display:flex;
	align-items:center;
	justify-content:center;
	height:100vh;
`


const FacaSuaPerguntaContainer = styled.div`
margin-left:30px;
width:400px;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
@media (max-width: 1200px) {
	margin-left:0px;
	max-width:90%;
}
`

const EnviePerguntaTitulo = styled.div`
color:#0071cb;
font-weight:500;
font-size:22px;
@media (max-width: 1200px){ 
	width:100%;
	text-align:center;
}
`

const EnviePerguntaTextArea = styled.textarea`
resize:none;
width:400px;
height:140px;
padding:20px;
font-size:22px;
margin-top:10px;
border:none;
color:#0071cb;
/* ::placeholder{
	
} */
@media (max-width: 1200px){ 
	width:100%;
	text-align:center;
}
`

const EnviePerguntaButton = styled.div`
margin-left:auto;
max-width:400px;
width:400px;
height:40px;
text-align:center;
text-transform:uppercase;
font-weight:500;
/* color:white; */
margin-top:10px;
display:flex;
align-items:center;
justify-content:center;
cursor:pointer;
/* background-color:${props => props.theme.purple}; */
background-color:#7a7a7a;
@media (max-width: 1200px){ 
	width:100%;
	text-align:center;
}
`