import styled from "styled-components"
import React from 'react'
import { Close } from "../../assets/Images"

export default function Termos({ confirmar }: any) {
	return (
		<Container>
			<Imagem>
				<span onClick={confirmar} style={{ cursor: 'pointer', position: 'absolute', top: 30, right: 30 }}>
					<Close />
				</span>
				<MessageContainer>
					<Titulo>
						Política de privacidade e dados
					</Titulo>
					<Text>
						Nosso site, acessado pelo domínio www.momentogalliprant.com.br, se destina a divulgação e
						transmissão do evento Momento Galliprant™. O serviço digital oferecido pela Elanco neste site
						diz respeito aos conteúdos abordados pelos palestrantes ao longo da transmissão do evento.
						<br /><br /><br />
						Neste site, estão disponíveis recursos de interação com o usuário, cujas políticas de respeito
						aos dados do usuário seguem descritas a seguir.
						<br /><br />
						Formulário de cadastro: Este formulário tem como objetivo captar dados para confirmação de
						presença no evento. Os campos de informação coletados neste formulário são: e-mail, nome
						completo, ocupação. A Elanco, gestora única e exclusiva de todo o conteúdo presente neste
						site institucional, se compromete a não realizar qualquer uso destes dados, senão e tão
						somente o objetivo de ter a confirmação de participação do convidado e o perfil da audiência
						participante.
						<br /><br />
						Após o término desta ação, com data marcada para 07/07/2021, os dados aqui gravados serão 
						guardados de forma segura, off-line em nossos arquivos de registro exclusivo da ação, e seus 
						dados não serão resgatados para nenhuma nova ação, nem em hipótese alguma 
						compartilhados com terceiros, sem o seu consentimento prévio. Serão, portanto, deletados da 
						rede on-line de nosso site, e iremos gerar um comunicado de agradecimento pela sua 
						participação, confirmando o apagamento de seus dados online e encerramento da ação.
					</Text>
					<Titulo style={{paddingTop:50}}>
						Cookies
					</Titulo>
					<Text>
						Os Cookies utilizados na programação do site Momento Galliprant, sob o domínio 
						www.momentogalliprant.com.br, seguem altos padrões de desenvolvimento e segurança de 
						implementação de cookies, e ficam armazenados no seu dispositivo a partir do seu primeiro 
						acesso, com as seguintes finalidades descritas a seguir:
						<br /><br />
							1) Para fornecer uma ótima experiência a seus visitantes e clientes.
						<br /><br />
							2) Monitorar e analisar o desempenho, operação e eficácia da plataforma.
						<br /><br />
							3) Para garantir que nossa plataforma seja segura.
					</Text>


				</MessageContainer>
			</Imagem>
		</Container>
	)
}

const MessageContainer = styled.div`
	height:50vh;
	max-height:50vh;
	overflow-y:auto;
`

const Titulo = styled.div`
	font-size:24px;
	font-weight:bold;
	margin-bottom:30px;
`

const Text = styled.div`
	font-size:18px;
	overflow-wrap: anywhere;
`

const Button = styled.div`
	height:70px;
	text-align:center;
	width:100%;
	color:#0071cb;
	border:0px;
	font-size:16px;
	font-weight:400;
	text-align:center;
	text-transform:uppercase;
	font-weight:500;
	color:white;
	margin-top:10px;
	display:flex;
	align-items:center;
	justify-content:center;
	cursor:pointer;
	background-color:${props => props.theme.purple};
`

const Container = styled.div`
	width:100vw;
	max-width:100%;
	height:100vh;
	max-height:100%;
	display:flex;
	align-items:center;
	justify-content:center;	
`

const Imagem = styled.div`
	position:relative;
    box-shadow: 0px 0px 80px 30px #ffffff14;
	/* background-image:url('/assets/background_sobre.jpg'); */
	background-size:cover;
	background-color:#fcc800;
	width:800px;
	/* min-height:400px; */
	/* height:90vh; */
	max-height:90vh;
	padding:40px;	
	padding-top:80px;
	padding-bottom:60px;
	white-space: pre-line;
	@media (max-width: 950px) {
		height:auto;
		max-height:100%;
	}
`