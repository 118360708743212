
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { checkChatStatus, getStreaming, loadPrevMessages, sendMessage } from '../../services/apiCalls';
import { getMessageState } from '../../store/slices/messages';
import videoState, { getVideoState } from '../../store/slices/video';
import PlenariaModeloSimples from '../../components/Plenaria/ModeloSimples/PlenariaModeloSimples';




function Chat({openModal}:any) {
	useEffect(() => {
		// loadPrevMessages(); // carrega mensagens antigas
		getStreaming(); // pega as informações do streaming/video atual
		checkChatStatus(); // pega info se o chat está ou não bloqueado
	}, []);
	
	const video = useSelector(getVideoState);
	const messages = useSelector(getMessageState);
	const dispatch = useDispatch();
	let [urlReproducao,setUrlReproducao] = useState('');

	useEffect(() => {
		if(!Array.isArray(video.urls) || video.urls.length === 0 )
			setUrlReproducao('');
		else{
			if(video.urls[video.videoAtual]){
				setUrlReproducao(video.urls[video.videoAtual].url)
			}else{
				setUrlReproducao(video.urls[0].url)
			}
		}
	}, [video]) // escolhe qual das urls de video devem ser tocadas

	
	function changeVideo(index:any){ //troca o index do video
		dispatch(videoState.actions.changeIdioma({videoAtual:index}));
	}
	
	return (
		<ContainerPlenaria>
			<PlenariaModeloSimples
				urlReproducao={urlReproducao} //url do video atual
				urls={video.urls} // urls para trocar entre tipos de video
				messages={messages.messages} // mensagens para o chat
				enableChat={video.chat} // se o chat está ativado
				sendMessage={sendMessage} // funcao para enviar mensagem do chat
				changeVideo={changeVideo} // callback que passa o index do video a ser tocado pensando primariamente para multiplos idiomas
				openModal={openModal}
			/>
		</ContainerPlenaria>
	);
}
const Titulo = styled.div`
	margin-top:40px;
	margin-bottom:40px;
	padding-left:10px;
	font-family:ink;
	text-transform:uppercase;
	color:${props => props.theme.red};
	font-size:34px;
	@media (max-width: 1390px) {
		font-size:33px;
	}

	@media (max-width: 730px) {
		text-align:center;
		font-size:20px;
	}
`

const ContainerPlenaria = styled.div`
	width:100%;
	height:100%;
	min-height:100%;
`

const Container = styled.div`
	display:flex;
	flex-grow: 1;
	width:100%;
	min-width:100%;
	height:100%;
	min-height:100vh;
	flex-direction:column;
`

export default Chat;
