import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import useWindowSize from '../../../hooks/useWindowSize';
import { getColor } from '../../../services/colors';
import Message from '../../Message/Message';
import SendMessage from '../../Message/SendMessage';
import { Try } from '../../Try/Try';
import Video from '../../video/Video'
import { PlenariaModeloSimplesProps } from './types';

export default function PlenariaModeloSimples({ urlReproducao, urls, messages, enableChat, sendMessage, changeVideo, openModal }: PlenariaModeloSimplesProps) {
	const refVideo = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLInputElement>;
	const refChatMessages = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLInputElement>;
	let [videoSize, setVideoSize] = useState(100);
	const [screenWidth] = useWindowSize()

	useEffect(() => {
		changeVideoSize();
	}, [screenWidth, refVideo])

	useEffect(() => {
		if (!refChatMessages) return;
		try {
			refChatMessages.current.scrollTop = refChatMessages.current.scrollHeight;
		} catch (e) {
			console.error("erro em dar scroll no chat");
		}
	});

	function changeVideoSize() {
		if (!refVideo.current) return;
		setVideoSize(refVideo.current?.getBoundingClientRect().height - 65);
		if (refVideo.current?.getBoundingClientRect().height - 65 < 0) {
			setTimeout(changeVideoSize, 100)
		}
	}
	return (
		<>
			<Container>
				<MainContainer>
					<VideoContent>
						<Titulo2 src="assets/juntos.png" />
						{enableChat && <EnviePerguntaButton className={enableChat ? '' : 'disabled'} onClick={() => { if (enableChat) openModal() }}><div>Faça sua Pergunta</div></EnviePerguntaButton>}
						<IFrameContiner ref={refVideo}>
							<Video src={urlReproducao} />
						</IFrameContiner>
						{urls && Array.isArray(urls) && urls.length > 1 && (<Audios>
								{urls.map((val: any, index: any) => <div key={index}><ButtonFlat onClick={() => { changeVideo(index) }}>{val.label}</ButtonFlat></div>)}
							</Audios>)}
					</VideoContent>
					{urls && Array.isArray(urls) && urls.length > 1 && <AudiosMobile>
						{urls.map((val: any, index: any) => <div key={index}><ButtonFlat onClick={() => { changeVideo(index) }}>{val.label}</ButtonFlat></div>)}
					</AudiosMobile>}
					{/* <Chat>
						<Label>Chat</Label>
						<ChatContainer style={{maxHeight:videoSize}}>
							<MessageContainer ref={refChatMessages}>
								{[...messages].map((val: any,index:any) => (
									<Try key={index}><Message nome={val.name_author || 'Anonimo'} hora={val.date} message={val.message} color={getColor(val.guid_author?val.guid_author.toUpperCase():'')} /></Try>
								))}
							</MessageContainer>
							<SendMessage enableChat={enableChat} click={sendMessage} />
						</ChatContainer>
					</Chat> */}
					{/* <FacaSuaPergunta>
						<EnviePerguntaTitulo>Envie Sua Pergunta</EnviePerguntaTitulo>
						<EnviePerguntaTextArea placeholder="Digite aqui"></EnviePerguntaTextArea>
						<EnviePerguntaButton><div>Enviar</div></EnviePerguntaButton>
					</FacaSuaPergunta> */}
				</MainContainer>

			</Container>
		</>
	)
}

const Titulo2 = styled.img`
	width:100%;
	height:auto;
	margin-top:80px;
	margin-bottom:40px;
`

const Titulo = styled.div`
	background-image:url('assets/juntos.png');
	background-color:red;
	height:50px;
	background-size:contain;
	width:100%;
	margin-top:40px;
	margin-bottom:40px;
	padding-left:10px;
	font-family:ink;
	text-transform:uppercase;
	color:${props => props.theme.red};
	font-size:34px;
	@media (max-width: 1390px) {
		font-size:33px;
	}

	@media (max-width: 730px) {
		text-align:center;
		font-size:20px;
	}
`

const FacaSuaPergunta = styled.div`
	margin-left:30px;
	width:400px;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
	@media (max-width: 1200px) {
		margin-left:0px;
		width:90%;
	}
`

const EnviePerguntaTitulo = styled.div`
	color:#0071cb;
	font-weight:500;
	font-size:22px;
	@media (max-width: 1200px){ 
		
	}
`

const EnviePerguntaTextArea = styled.textarea`
	resize:none;
	width:400px;
	height:140px;
	padding:20px;
	font-size:22px;
	margin-top:10px;
	border:none;
	color:#0071cb;
	/* ::placeholder{
		
	} */
	@media (max-width: 1200px){ 
		width:100%;
	}
`

const EnviePerguntaButton = styled.div`
	margin-left:auto;
	margin-bottom:10px;
	max-width:170px;
	width:170px;
	height:40px;
	text-align:center;
	text-transform:uppercase;
	font-weight:500;
	/* color:white; */
	margin-top:10px;
	display:flex;
	align-items:center;
	justify-content:center;
	cursor:pointer;
	color:white;
	/* background-color:${props => props.theme.purple}; */
	background-color:#0071cb;
	&.disabled{
		cursor:not-allowed;
		background-color:#7a7a7a;
	}
	@media (max-width: 1200px){ 
		width:100%;
	}

	@media (max-width: 600px){ 
		width:100%;
		max-width:100%;
		min-width:100%;
	}
`

const ButtonFlat = styled.div`
	cursor: pointer;
	border: 2px solid white;
	height:40px;
	padding:10px;
	color:white;
	display:flex;
	text-align:center;
	align-items:center;
	justify-content:center;
	text-transform:uppercase;
	font-size:12px;
	white-space: nowrap;

	&:hover{
		font-weight:bold;
		border-color:white;
		color:${props => props.theme.darkBlue};
		background-color:white;
	}
`
const AudiosMobile = styled.div`
	display:none;
	flex-direction:row;
	gap:10px;
	@media (max-width: 1200px){ 
		display:flex;
	}
`

const Audios = styled.div`
	padding-top:10px;
	align-self:center;
	justify-self:center;
	display:flex;
	flex-direction:row;
	gap:10px;
	margin-bottom:30px;
	width:100%;
	/* max-width:calc((100vh) * 1.777 - 40vw); */
	/* max-width:min(calc((100vh - 170px) * 16 / 9 - 60px),100%); */
	@media (max-width: 1200px) {
		display:none;
	}
`
const MainContainer = styled.div`
	height:100%;
	display: flex;
	flex-direction: row;
	width: 100%;
	gap:10px;
	align-items:center;
	justify-content:center;
	@media (min-width: 1200px){ 
		padding-bottom:5px;
	}

	@media (max-width: 1200px){ 
		max-width:100%;
		flex-direction: column;
		gap:15px;
	}
`
const ChatContainer = styled.div`
	flex-grow:1;
	display:flex;
	flex-direction:column;
	@media (max-width: 1200px){ 
		min-height:640px;
  	}
`
const MessageContainer = styled.div`
	background-color:white;
	flex-grow:1;
	overflow-y: scroll;
	border-radius:2px;
	background-color:white;
`

const Label = styled.div`
  color:${props => props.theme.AccentBlue};
  padding-bottom:10px;
  font-size:18;
  font-weight:500;
`

const VideoContent = styled.div`
	display: flex;
	flex-direction: column;
	width:calc(100vw - 600px);
	max-width:min(calc((100vh - 170px) * 16 / 9 - 60px),100%);
	@media (max-width: 1200px) {
		width: calc(100vw);
		max-width:min(calc((100vh - 170px) * 16 / 9 - 10px),90%);
	}
`

const Chat = styled.div`
  width: 700px;
  padding:12px;
  min-height:100%;
  background-color:${props => props.theme.chatTheme.borderMessages};
  color:white;
  font-size:20px;
  font-weight:bold;
  display:flex;
  flex-direction:column;
  
  @media (max-width: 1200px){ 
	width:100%;
	max-height:700px;
	min-height:700px;
  }
`

const IFrameContiner = styled.div`
	position:relative;
	z-index: 2;
	border-radius:6px;
	& img {display:block;width:100%;height:auto;}
	& iframe{
		position:absolute;
		top:0;
		left:0;
		width:100%; 
		height:100%;
	}
`


const Container = styled.div`
	width:100%;
	min-width:100%;
`