import i18n from 'i18next';
import brasil from './pt-BR/brasil.json';
import english from './en/english.json';
import { initReactI18next } from 'react-i18next';

export const resources = {
  'pt-BR': {
    translation:brasil,
  },
  'en': {
    translation:english,
  },
};

i18n.use(initReactI18next).init({
  lng: 'pt-BR',
  resources,
  fallbackLng:'pt-BR'
});