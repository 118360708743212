import React, { CSSProperties } from 'react'

import {ReactComponent as SetaSVG} from './SVGs/Seta.svg'
import {ReactComponent as CloseSVG} from './SVGs/Close.svg'
import {ReactComponent as BarsSolidSVG} from './SVGs/bars-solid.svg'

import Img16x9PNG from './images/16x9.png'
import BackgroundJPG from './images/background_e.jpg'
import BackgroundSobreJPG from './images/background_sobre.jpg'
import MovimentoPNG from './images/movimento.png'
import GalliptrantPNG from './images/galliprant.png'

// SVG
export const MenuIcon = () => <BarsSolidSVG />
export const Seta = () => <SetaSVG fill={'#bf0c3d'} stroke={'#bf0c3d'}/>
export const Close = () => <CloseSVG fill={'#bf0c3d'} stroke={'#bf0c3d'}/>

// images
export const Img16x9 = ({style}:{style?:CSSProperties}) => <img style={{...style}} className="p_16x9" src={Img16x9PNG} alt="placeholder video"/>
export const Background = ({style}:{style?:CSSProperties}) => <img style={{...style}} className="background" src={BackgroundJPG} alt="background"/>
export const Background_sobre = ({style}:{style?:CSSProperties}) => <img style={{...style}} className="background_sobre" src={BackgroundSobreJPG} alt="background_sobre"/>
export const Movimento = ({style}:{style?:CSSProperties}) => <img style={{...style}} className="movimento" src={MovimentoPNG} alt="movimento"/>
export const Galliptrant = ({style}:{style?:CSSProperties}) => <img style={{...style}} className="galliprant" src={GalliptrantPNG} alt="galliprant logo"/>
