import { createSlice } from '@reduxjs/toolkit'


const initialState:{messages:any[]} = {
	messages:[]
}

const messageState = createSlice({
  name: 'Messages',
  initialState,
  reducers: {
    addMessage: (state,action:any) => {
		if(!action.payload.guid || typeof action.payload.guid !== "string") return;
		if(!action.payload.guid_chat || typeof action.payload.guid_chat !== "string") return;
		if(!action.payload.guid_author || typeof action.payload.guid_author !== "string") return;
		if(!action.payload.name_author || typeof action.payload.name_author !== "string") return;
		if(!action.payload.date || typeof action.payload.date !== "string") return;
		state.messages=[...state.messages,action.payload];
	},
	removeMessage: (state,action:any) => {
		const {guid} = action.payload;
		if(!state.messages && typeof action.payload.date !== "string") return;
		state.messages = state.messages.filter(val => val.guid.toLowerCase() !== guid.toLowerCase());
	},
	clean: (state) => { state.messages = []},
	prevMessages:(state,action) =>{
		if(!Array.isArray(action.payload)) return;
		action.payload.filter(val => {
			if(!val.guid || typeof val.guid !== "string") return false;
			if(!val.guid_chat || typeof val.guid_chat !== "string") return false;
			if(!val.guid_author || typeof val.guid_author !== "string") return false;
			if(!val.name_author || typeof val.name_author !== "string") return false;
			if(!val.date || typeof val.date !== "string") return false;
			return true;
		})
		state.messages=[...action.payload,...state.messages];
		
	}
  }
})

export const getMessageState = (state:any) => state[messageState.name]

export default messageState
