import React from 'react'
import styled from "styled-components";
import { MenuIcon } from '../../assets/Images';

export default function MenuButton({onClick}:any) {
	return (
		<BarsButton onClick={()=>onClick()}>
			<MenuIcon />
		</BarsButton>
	)
}

const BarsButton = styled.div`
	cursor: pointer;
	height:40px;
	width:40px;
	padding:10px;
	color:white;
	display:flex;
	text-align:center;
	align-items:center;
	justify-content:center;
	text-transform:uppercase;
	font-size:12px;
	white-space: nowrap;

	& svg{
		width: 20px;
		height: auto;
	}

`