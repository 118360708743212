import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import styled from 'styled-components'
import { Movimento } from '../../assets/Images'
import MenuButton from './MenuButton';

export default function NavbarMobile() {
	const [showMenu, setShowMenu] = useState(false);
	const toggleMenu = () => {
		setShowMenu(!showMenu);
	}
	const history = useHistory();
	return (
		<Container>
			<Col1>
				<Movimento />
			</Col1>
			<BarraMenuResponsivo>
				<MenuButton onClick={() => toggleMenu()} />
				{/* <AssistaAgora>
					<Button onClick={() => {history.push(`/chat`)}}>Assista Agora</Button>
				</AssistaAgora> */}
			</BarraMenuResponsivo>
			<MenuResponsivo style={showMenu ? {left: 0}:{left: '-100vw'}}>
				<div onClick={() => {history.push('/home#home');toggleMenu();}}>Home</div>
				<div onClick={() => {history.push('/home#agenda');toggleMenu();}}>Agenda e Speakers</div>
				{/* <div onClick={() => {history.push('/home#speaker');toggleMenu();}}>Speakers</div> */}
			</MenuResponsivo>
		</Container>
	)
}

const Container = styled.div`
	background-color:${props => props.theme.yellow};
	width:100%;
	min-width:100%;
	position:sticky;
	display:none;
	flex-direction:column;
	z-index:999;
	top:0;
	@media (max-width: 950px) {
		display:flex;
	}
`

const Col1 = styled.div`
	max-height:100px;
	width:100%;
	min-width:100%;
	align-items:center;
	justify-content:center;
	text-align: center;
	& img{
		width:auto;
		height: 90px;
    	max-height: 90px;
	}

`

const BarraMenuResponsivo = styled.div`
		align-items: center;
		display: flex;
		width: 100%;
		height: 50px;
		background-color:${props => props.theme.red};
`;

const IconeMenuResposivo = styled.div`
	
`;

const AssistaAgora = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: end;
	align-content: center;
	justify-content: flex-end;
	margin-right:10px;
`;

const MenuResponsivo = styled.div`
	width: 90vw;
	height: 100vh;
	background-color: ${props => props.theme.red + "E3"};
	backdrop-filter: blur(10px);
	position: fixed;
	top:0;
	left: 0;
	z-index: 300;
	padding-top: 10px;
	margin-top: 100px;

	transition: left .5s;

	flex-direction: column;
	list-style-type: none;
	justify-content: space-evenly;
	align-items: flex-start;

	font-size:22px;
	font-weight:500;
	color:white;
	& div{
		padding:20px;
		cursor: pointer;
		text-transform: uppercase;
		padding-right: 15px;
		padding-left: 15px;
		border-right:0.2em solid ${props => props.theme.purple};
	}
	& div:last-child{
		border-right:none;
	}
`;

const Button = styled.div`
	cursor: pointer;
	border: 2px solid ${props => props.theme.purple};
	background-color:${props => props.theme.yellow};
	height:40px;
	padding:10px;
	color:${props => props.theme.purple};
	font-weight:500;
	display:flex;
	text-align:center;
	align-items:center;
	justify-content:center;
	text-transform:uppercase;
	font-size:12px;
	white-space: nowrap;

	&:hover{
		border: 2px solid ${props => props.theme.purple};
		color:white;
		background-color:${props => props.theme.purple};
	}
`