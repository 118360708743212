import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Close } from '../../assets/Images';

export default function Modal({ open,children }: any) {

	const [isOpen, setIsOpen] = useState(false);
	const [animationTime, setAnimationTime] = useState(1);

	useEffect(() => {
		setAnimationTime(1);
		setIsOpen(open);
	}, [open])


	return (
		<Background animationTime={animationTime} className={`${isOpen ? 'open' : 'close'}`}>
			<Container animationTime={animationTime} className={`${isOpen ? 'open' : 'close'}`} >
				{children}
			</Container>
		</Background>
	)
}

const Background = styled.div<{ animationTime: number }>`
	position:fixed;
	width:100vw;
	max-width:100%;
	height:100vh;
	max-height:100%;
	background-color:#00000085;
	z-index:1050;

	&.close{
		visibility:hidden;
		opacity: 0;
		transition: opacity ${props => props.animationTime}s, visibility 0s linear ${props => props.animationTime * 1000}ms;
		@media (min-width: 950px) {
			
		}
		@media (max-width: 949px){
			
		}

	}
	&.open{
		opacity: 1;
		visibility:visible;
		transition:opacity ${props => props.animationTime}s, visibility 0s linear 0ms;

		@media (min-width: 950px) {
		
		}
		@media (max-width: 949px){
		
		}
	}
`

const Container = styled.div<{ animationTime: number }>`
	position:fixed;
	width:100vw;
	max-width:100%;
	height:100vh;
	max-height:100%;
	z-index:1050;

	&.close{
		visibility:hidden;
		transition: transform ${props => props.animationTime}s, visibility 0s linear ${props => props.animationTime * 1000}ms;
		@media (min-width: 950px) {
			transform:translateX(100vw);
		}
		@media (max-width: 949px){
			transform:translateY(100vh);
		}

	}
	&.open{
		visibility:visible;
		transition: transform ${props => props.animationTime}s, visibility 0s linear 0ms;

		@media (min-width: 950px) {
			transform:translateX(0);
		}
		@media (max-width: 949px){
			transform:translateY(0);
		}
	}
`