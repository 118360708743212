import React from 'react'
import styled from 'styled-components'

export default function MarcaDagua({imgUrl}:{imgUrl:string}) {
	return (
		<>
			<ContainerLogo imgUrl={imgUrl} />
			<ContainerBackImage imgUrl="/assets/background_e.jpg"/>
			<ContainerDog imgUrl="/assets/Dog2.png"/>
			<Footer>
				<div>Galliprant™, Elanco e o logotipo de barras diagonais são marcas registradas da Elanco ou de suas filiais. Todos os direitos reservados. Julho/2021. PM-BR-21-0483</div>
			</Footer>
		</>
	)
}

const Footer = styled.div`
	display:flex;
	align-items:center;
	font-size:10px;
	padding-left:20px;
	color:white;
	background-color:#c6043d;
	height:50px;
	width:100%;
	position:fixed;
	bottom:0;
	left:0;
	z-index:999;
`

const Container = styled.div<{imgUrl:string}>`
	position: fixed;
	background-image:url('${props => props.imgUrl}');
	bottom: 0;
	right:0;
    height: 100vh;
	pointer-events: none;
    width: 40%;
	max-width: 550px;
	background-repeat:no-repeat;
    background-position-y: bottom;
	background-size:contain;
	@media (max-width: 950px) {
		width: 100%;
	}
`
const ContainerLogo = styled(Container)`
	z-index:9;
	bottom:50px;
	min-width:200px;
	width: 20%;
	max-width:calc(100vh/1.4);
`;

const ContainerDog = styled(Container)`
	z-index:1;
	background-position-y: 230px;
	/* background-position-x: right; */
	width:20%;
	min-width:20%;
	max-width:20%;
	position:absolute;
	@media (max-width: 1200px) {
		width:25%;
		min-width:25%;
		max-width:25%;
	}
	@media (max-width: 600px) {
		width:100%;
		min-width:100%;
		max-width:100%;
		background-size: contain;
		background-position-y: 130px;
	}
	@supports (-webkit-touch-callout: none) {
		background-position-y: 230px;
	}

`
const ContainerBackImage = styled(Container)`
	position:absolute;
	z-index:0;
	background-size:cover;
	width:100%;
	min-width:100%;
	max-width:100%;
`