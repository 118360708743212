import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import MarcaDagua from '../../components/MarcaDagua/MarcaDagua'
import Modal from '../../components/Modal/Modal'
import Descricao from '../../customComponents/Descricao/Descricao'
import Navbar from '../../customComponents/Navbar/Navbar'
import { getLoginState } from '../../store/slices/login'
import Agenda from './Agenda'
import Chat from './Chat'
import FacaSuaPergunta from './FacaSuaPergunta'
import Speaker from './Speaker'

export default function Home() {
	const [modalOpen,setModalOpen] = useState(false);
	const [modalInfo,setModalInfo] = useState(0);

	const [modalPergunta,setModalPergunta] = useState(false);
	const { hash } = useLocation();
	const history = useHistory();
	const login = useSelector(getLoginState);
	useEffect(() => { if (login.token == null) { history.push("/") }  if (login.realizouCadastro == false) { history.push("/cadastro") } }, [])
	
	const OpenModal = (info:any) => {
		setModalOpen(true);
		setModalInfo(info)
	}

	const CloseModal = () => {
		setModalOpen(false);
	}

	useEffect(() => {
		if(document.getElementById(hash.slice(1)))
			document.getElementById(hash.slice(1))?.scrollIntoView({behavior:'smooth'});
		else
			window.scrollTo({top:0,behavior:'smooth'});
	  }, [hash]);

	return (
		<>
		<Navbar />
		<Modal open={modalOpen}>
			<Descricao id={modalInfo} closeModal={CloseModal} />
		</Modal>
		
		<Modal open={modalPergunta}>
			<FacaSuaPergunta modalStatus={modalPergunta} closeModal={() => setModalPergunta(false)} />
		</Modal>

		<Center>
		<MarcaDagua imgUrl="/assets/galliprant.png" />
		
		<Container>
			<div style={{height:50}}/>
			<Chat openModal={() => setModalPergunta(true)} />
			<Agenda openModal={OpenModal} />
			{/* <Speaker click={OpenModal}/> */}
		</Container>
		</Center>
		</>
	)
}

const Center = styled.div`
	display:flex;
	flex-direction: column;
	max-width: 60%;
	align-self:center;
	flex-grow:1;
	@media (max-width: 600px) {
		width:100%;
		min-width:100%;
		max-width:100%;
	}
`

const Container = styled.div`
	z-index:2;
	align-items: center;
	display:flex;
	flex-direction:column;
	/* background-color:${props => props.theme.yellow}; */
	/* background-image:url('/assets/background_e.jpg'); */
	background-attachment: fixed;
	padding-bottom:calc(100vw * 0.4 * 0.38);
	@media (max-width: 950px) {
		padding-bottom:calc(100vw * 0.38);
	}
`
