import React from "react";
import { useSelector } from "react-redux";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";
import { getLoginState } from "../store/slices/login";
import Cadastro from "../views/Cadastro/Cadastro";
import Home from "../views/Home/Home";
import Login from "../views/Login/Login";

function RouterComponent() {
	const login = useSelector(getLoginState);
	return (
		<>
			<Router>
				<Switch>
					<Route exact path="/">
						<Login />
					</Route>
					<Route exact path="/home">
						<Home />
					</Route>
					<Route exact path="/cadastro">
						<Cadastro />
					</Route>

					{/* <Route exact path="/login">
						<Login />
					</Route>
					<GuardedRoute path="/chat" permission={login.token != null} redirect="/login">
						<Chat />
					</GuardedRoute> */}
					<Route path="/">
						<Redirect to="/" />
					</Route>
				</Switch>
			</Router>
		</>
	);
}

export default RouterComponent;
