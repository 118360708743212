import styled from "styled-components"
import React from 'react'

export default function Confirma({confirmar}:any) {
	return (
		<Container>
			<Imagem>
				<MessageContainer>
					<Text>Que pena, esperamos poder contar com você na próxima oportunidade. Aproveite para acessar agora nosso site e saber mais sobre a programação que teremos no  evento do dia 07 de Julho.</Text>
					<Button onClick={confirmar}>CONFIRMAR</Button>
				</MessageContainer>
			</Imagem>
		</Container>
	)
}

const MessageContainer = styled.div`
`

const Text = styled.div`
	font-size:18px;
`

const Button = styled.div`
	height:70px;
	text-align:center;
	width:100%;
	color:#0071cb;
	border:0px;
	font-size:16px;
	font-weight:400;
	text-align:center;
	text-transform:uppercase;
	font-weight:500;
	color:white;
	margin-top:10px;
	display:flex;
	align-items:center;
	justify-content:center;
	cursor:pointer;
	background-color:${props => props.theme.purple};
`

const Container = styled.div`
	width:100vw;
	max-width:100%;
	height:100vh;
	max-height:100%;
	display:flex;
	align-items:center;
	justify-content:center;	
`

const Imagem = styled.div`
	position:relative;
    box-shadow: 0px 0px 80px 30px #ffffff14;
	/* background-image:url('/assets/background_sobre.jpg'); */
	background-size:cover;
	background-color:#fcc800;
	width:800px;
	/* min-height:400px; */
	/* height:90vh; */
	max-height:90vh;
	padding:40px;	
	padding-top:80px;
	padding-bottom:60px;
	white-space: pre-line;
	@media (max-width: 950px) {
		height:auto;
		max-height:100%;
	}
`