import { createSlice } from '@reduxjs/toolkit'

const redirectToState = createSlice({
  name: 'redirectToState',
  initialState: {
	redirect:'/'
  },
  reducers: {
    changeRedirect: (state,action:any) => {
		state.redirect=action.payload;
	},
  }
})

export const getRedirectToState = (state:any) => state[redirectToState.name]

export default redirectToState
