export const Theme = {
	yellow:'#fcc800',
	purple:'#8e288e',
	red:'#bf0c3d',
	blue:'#0070ca',
	background:'#fcc800',
	darkBlue:'#00194f',
	darkBlue3:'#002050',
	lightBlue:'#A9E1FE',
	orange:'#FF4E33',

	chatTheme:{
		borderMessages:'#FF4E33',
		borderVideo:''
	}
  }
  