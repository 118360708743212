import React from 'react'
import NavbarDesktop from './NavbarDesktop'
import NavbarMobile from './NavbarMobile'

export default function Navbar() {
	return (
		<>
			<NavbarDesktop />
			<NavbarMobile />
		</>
	)
}
