import { createGlobalStyle } from 'styled-components'

import InkyflutterbyEot from './inkyflutterby-webfont.eot'
import InkyflutterbyWoff2 from './inkyflutterby-webfont.woff2'
import InkyflutterbyWoff from './inkyflutterby-webfont.woff'
import InkyflutterbyTTF from './inkyflutterby-webfont.ttf'
import InkyflutterbySVG from './inkyflutterby-webfont.svg'


import SolomonEot from './solomon_sans_book-webfont.eot'
import SolomonWoff2 from './solomon_sans_book-webfont.woff2'
import SolomonWoff from './solomon_sans_book-webfont.woff'
import SolomonTTF from './solomon_sans_book-webfont.ttf'
import SolomonSVG from './solomon_sans_book-webfont.svg'

import SolomonBoldEot from './solomon_sans_bold-webfont.eot'
import SolomonBoldWoff2 from './solomon_sans_bold-webfont.woff2'
import SolomonBoldWoff from './solomon_sans_bold-webfont.woff'
import SolomonBoldTTF from './solomon_sans_bold-webfont.ttf'
import SolomonBoldSVG from './solomon_sans_bold-webfont.svg'


export default createGlobalStyle`

	@font-face {
	font-weight: 300;
	font-family: 'Ink';
	font-style: normal;
	src: url(${InkyflutterbyEot}); /* IE9 Compat Modes */
	src: url(${InkyflutterbyWoff2}) format('woff2'), /* Super Modern Browsers */
		url(${InkyflutterbyWoff}) format('woff'), /* Modern Browsers */
		url(${InkyflutterbyTTF}) format('truetype'); /* Safari, Android, iOS */
	}

	@font-face {
	font-weight: 400;
	font-family: 'Ink';
	font-style: normal;
	src: url(${InkyflutterbyEot}); /* IE9 Compat Modes */
	src: url(${InkyflutterbyWoff2}) format('woff2'), /* Super Modern Browsers */
		url(${InkyflutterbyWoff}) format('woff'), /* Modern Browsers */
		url(${InkyflutterbyTTF}) format('truetype'); /* Safari, Android, iOS */
	}

	@font-face {
	font-weight: 300;
	font-family: 'Solomon';
	font-style: normal;
	src: url(${SolomonEot}); /* IE9 Compat Modes */
	src: url(${SolomonWoff2}) format('woff2'), /* Super Modern Browsers */
		url(${SolomonWoff}) format('woff'), /* Modern Browsers */
		url(${SolomonTTF}) format('truetype'); /* Safari, Android, iOS */
	}
	@font-face {
	font-weight: 400;
	font-family: 'Solomon';
	font-style: normal;
	src: url(${SolomonEot}); /* IE9 Compat Modes */
	src: url(${SolomonWoff2}) format('woff2'), /* Super Modern Browsers */
		url(${SolomonWoff}) format('woff'), /* Modern Browsers */
		url(${SolomonTTF}) format('truetype'); /* Safari, Android, iOS */
	}

	@font-face {
	font-weight: 500;
	font-family: 'Solomon';
	font-style: normal;
	src: url(${SolomonBoldEot}); /* IE9 Compat Modes */
	src: url(${SolomonBoldWoff2}) format('woff2'), /* Super Modern Browsers */
		url(${SolomonBoldWoff}) format('woff'), /* Modern Browsers */
		url(${SolomonBoldTTF}) format('truetype'); /* Safari, Android, iOS */
	}

	@font-face {
	font-weight: 600;
	font-family: 'Solomon';
	font-style: normal;
	src: url(${SolomonBoldEot}); /* IE9 Compat Modes */
	src: url(${SolomonBoldWoff2}) format('woff2'), /* Super Modern Browsers */
		url(${SolomonBoldWoff}) format('woff'), /* Modern Browsers */
		url(${SolomonBoldTTF}) format('truetype'); /* Safari, Android, iOS */
	}

`