import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Close } from '../../assets/Images'
import store from '../../store/'
import { getSpeakerState } from '../../store/slices/speaker'

function Descricao({closeModal,id,speakers}:any) {
	console.log(speakers.dados);
	let speaker = speakers.dados.find((val:any) => val.Id === id);
	console.log("speaker");

	if(!speaker) return (
		<Container>
			<Imagem>
				<span onClick={closeModal} style={{cursor:'pointer',position:'absolute',top:30,right:30}}>
					<Close />
				</span>
			</Imagem>
		</Container>
	)
	return (
		<Container>
			<Imagem>
				<span onClick={closeModal} style={{cursor:'pointer',position:'absolute',top:30,right:30}}>
					<Close />
				</span>
				<Center>
					<OrangeBar>
						<Foto src={speaker.Imagem} />
						<Social>
							{speaker.Linkedin && 
							(<span onClick={() => window.open(speaker.Linkedin)}>
								{/* <Linkedin/> */}
							</span>)}
							{
								speaker.Mail && (<span onClick={() => window.location.href = `mailto:${speaker.Mail}`}>
									{/* <Mail /> */}
								</span>)}
						</Social>
					</OrangeBar>
					<TextArea>
						<Titulo>{speaker.Nome} {speaker.Pais && `(${speaker.Pais})`}</Titulo>
						<SubTitulo>{speaker.Profissao}</SubTitulo>
						<Texto>{speaker.Perfil}</Texto>
						{speaker.palestras.map((val:any) => (
							<>
								<Titulo>{val.titulo}</Titulo>
								<Texto>{val.descricao}</Texto>
							</>
						))}
						{/* <Texto>{speaker.Perfil}</Texto> */}
					</TextArea>
				</Center>
			</Imagem>
		</Container>
	)
}

export default connect(state => ({speakers: getSpeakerState(store.getState()) }))(Descricao);

const Container = styled.div`
	width:100vw;
	max-width:100%;
	height:100vh;
	max-height:100%;
	display:flex;
	align-items:center;
	justify-content:center;	
`

const Imagem = styled.div`
	position:relative;
    box-shadow: 0px 0px 80px 30px #ffffff14;
	background-image:url('/assets/background_sobre.jpg');
	background-size:cover;
	background-color:red;
	width:800px;
	/* min-height:400px; */
	height:90vh;
	max-height:800px;
	padding:40px;	
	padding-top:80px;
	padding-bottom:60px;
	white-space: pre-line;
	@media (max-width: 950px) {
		height:auto;
		max-height:100%;
	}
`

const Center = styled.div`
	max-height:100%;
	min-height:100%;
	display:flex;
	flex-direction:row;
	@media (max-width: 950px) {
		flex-direction:column;
		max-height: calc(100vh - 140px);
	}
	@supports (-webkit-touch-callout: none) {
		max-height: calc(100vh - 340px);
	}
`

const Foto = styled.img`
	width: auto;
	height: 180px;
	left: -20px;
	top: 20px;
	position: absolute;
`


const Social = styled.div`

`
const OrangeBar = styled.div`
	position: relative;
	width:150px;
	min-width:150px;
	margin-left:50px;
	background-color:${props => props.theme.red};
	padding-top:210px;
	& span{
		margin-left:10px;
		cursor:pointer;
	}
`

const TextArea = styled.div`
	margin-left:30px;
	margin-top:30px;
	overflow-y:auto;
	width:100%;
	max-height: 650px;
	@media (max-width: 950px) {
		margin-left:0px;
	}
`

const Titulo = styled.div`
	margin-top:20px;
	color:${props => props.theme.red};
	font-size:24px;
	font-weight:700;
	line-height:22px;
	user-select: none;
`

const SubTitulo = styled.div`
	color:purple;
	line-height:22px;
	font-size:18px;
	margin-top:4px;
	user-select: none;
`

const Texto = styled.div`
	color:purple;
	font-size:16px;
	line-height:22px;
	margin-top:20px;
`


