import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Movimento } from '../../assets/Images'
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { cadastro } from '../../services/apiCalls';
import { useDispatch, useSelector } from 'react-redux';
import loginState, { getLoginState } from '../../store/slices/login';
import Modal from '../../components/Modal/Modal'
import Confirma from './Confirma';


export default function Cadastro() {
	const [confirmaPresenca,setConfirmaPresenca]:any = useState(false);
	const [nome,setNome]:any = useState('');
	const [ocupacao,setOcupacao]:any = useState('');
	const [camposComProblemas,setCamposComProblemas]:any = useState([]);
	const [erroCadastro,setErroCadastro] = useState('');
	const [modalOpen,setModalOpen] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();
	const login = useSelector(getLoginState);
	useEffect(() => { if (login.token == null) { history.push("/") }  if (login.realizouCadastro == true) { history.push("/home") } }, [])

	const enterAction = (e:any) => {
		if (e.key === 'Enter') {
			doCadastro();
		}
	}

	async function doCadastro(){
		const camposComProblemas = [];
		if(nome.length < 3) camposComProblemas.push("Nome");
		if(ocupacao.length < 3) camposComProblemas.push("Ocupacao");
		// if(confirmaPresenca === null) camposComProblemas.push("ConfirmarPresenca");
		setCamposComProblemas(camposComProblemas);
		if (camposComProblemas.length !== 0) {				
			setErroCadastro("Todos os campos são de preenchimento obrigatório.");
			return;
		}
		
		const res = await cadastro({
			"ocuppation":ocupacao,
			"name":nome,
			"present":'0',
		},0);

		

		if (res) {
			switch (res.status) {
				case 1:
					await dispatch(loginState.actions.realizaCadastro({ realizouCadastro: "1" }));
					history.push("/home");
					// if(!confirmaPresenca){
					// 	setModalOpen(true);
					// }else{
					// 	history.push("/home");
					// }
					return true;
					break;
				case -1:
				case -2:
				case -3:
				case -4:
					await dispatch(loginState.actions.logout());
						history.push("/")
						return;
					break;
				default:
					// setErroCadastro("Todos os campos são de preenchimento obrigatório.");
					return;
					break;
			}
		} else {
			await dispatch(loginState.actions.logout());
			history.push("/");
		}
	}
	
	
	return (
		<>
			<Modal open={modalOpen}>
				<Confirma confirmar={() => {setModalOpen(false);setTimeout(() => history.push('/home'),500) }} />
			</Modal>
			<Logo />
			<Footer>
				<div>Galliprant™, Elanco e o logotipo de barras diagonais são marcas registradas da Elanco ou de suas filiais. Todos os direitos reservados. Julho/2021. PM-BR-21-0483</div>
			</Footer>
			<Container>
				<Content>
					<Movimento />
					<div style={{height:30}} />
					<Input className={camposComProblemas.includes("Nome")?'erro':''} value={nome} onChange={(e) => setNome(e.target.value)} placeholder="NOME COMPLETO" />
					<div style={{height:10}} />
					<SelectWrapper>
						<Select className={camposComProblemas.includes("Ocupacao")?'erro':''} onChange={(e) => setOcupacao(e.target.value)}>
							<option value="-1">OCUPAÇÂO</option>
							<option>Médico Veterinário </option>
							<option>Estudante </option>
							<option>Lojista </option>
							<option>Distribuidor </option>
							<option>Outro </option>
						</Select>
					</SelectWrapper>
					<div style={{height:30}} />
					{/* <Texto>Confirma sua presença no evento</Texto> */}
					{/* <CheckBoxContainer className={camposComProblemas.includes("ConfirmarPresenca")?'erro':''}>
						<GreenCheckbox
							checked={confirmaPresenca === true}
							onChange={(e:any) => setConfirmaPresenca(!!e.target.checked?true:null)}
							name="checkedB"
						/>
						<div>SIM</div>
						<GreenCheckbox
							checked={confirmaPresenca === false}
							onChange={(e:any) => setConfirmaPresenca(!!e.target.checked?false:null)}
							name="checkedB"
						/>
						<div>NÂO</div>
					</CheckBoxContainer> */}
					{erroCadastro && <ErrorMessage><div>{erroCadastro}</div></ErrorMessage>}
					<Button onClick={doCadastro}><div>Confirmar</div></Button>
				</Content>
			</Container>
		</>
	)
}

const ErrorMessage = styled.div`
font-size:20px;
	color:#bf0c3d;
	font-weight:500;
`

const CheckBoxContainer = styled.div`
	display:flex;
	flex-direction:row;
	align-items:center;

	&.erro{
		color:#bf0c3d;
	}
`

const GreenCheckbox = withStyles({
	root: {
	  color: '#0071cb',
	  '&$checked': {
		color: '#0071cb',
	  },
	},
	checked: {},
  })((props:any) => <Checkbox color="default" {...props} />);
  

const Texto = styled.div`
	color:black;
	text-align:center;
	font-size:18px;
	text-transform:uppercase;
`

const Content = styled.div`
	display:flex;
	align-items:center;
	max-width:500px;
	width:500px;
	min-width:500px;
	height:500px;
	margin-left:10%;
	flex-direction:column;
	

	& .movimento {
		width: 239px;
	    height: 153px;
	}

	@media (max-width: 600px) {
		height:100%;
		max-width: 90%;
		width: 90%;
		min-width:90%;
		margin-left:0%;
	}

	@media (max-width: 1100px) {
		height:100%;
	}
`

const Input = styled.input`
	padding-left:40px;
	margin-top:auto;
	background-color:white;
	height:70px;
	text-align:left;
	width:100%;
	color:#0071cb;
	border:0px;
	font-size:20px;
	font-weight:400;

	&.erro{
		background-color:#bf0c3d33
	}

	::placeholder{
		color:#0071cb;
	}
`
const SelectWrapper = styled.div`
	width:100%;
	position: relative;
	&:after {
		pointer-events: none;
		content: "▼";
		font-size: 22px;
		color:#0071cb;
		top: 20px;
		right: 20px;
		position: absolute;
	}
`

const Select = styled.select`
	cursor:pointer;
	background-color:white;
	height:70px;
	text-align:center;
	width:100%;
	color:#0071cb;
	border:0px;
	font-size:20px;
	font-weight:400;
	padding-left:40px;
	appearance: none;

    &.erro{
		background-color:#bf0c3d33
	}

	::placeholder{
		color:#0071cb;
	}
`

const Button = styled.div`
	height:70px;
	text-align:center;
	width:100%;
	color:#0071cb;
	border:0px;
	font-size:16px;
	font-weight:400;
	text-align:center;
	text-transform:uppercase;
	font-weight:500;
	color:white;
	margin-top:10px;
	display:flex;
	align-items:center;
	justify-content:center;
	cursor:pointer;
	background-color:${props => props.theme.purple};
`

const Container = styled.div`
	max-width:100%;
	max-height:100%;
	min-width:250px;
	width:100vw;
	height:100vh;
	background-image:url('assets/cadastro_fundo.jpg');
	background-size:cover;
	background-position:center;
	display:flex;
	align-items:center;
	background-repeat:no-repeat;
	min-height:650px;

	@media (max-width: 1100px) {
		padding-top:10px;
		padding-bottom:150px;
		min-height:650px;
	}

	@media (max-width: 600px) {
		padding-top:0px;
		padding-bottom:150px;
		background-size:cover;
		justify-content:center;
		min-height:600px;
		align-items:flex-start;
	}

`


const Footer = styled.div`
	display:flex;
	align-items:center;
	font-size:10px;
	padding-left:20px;
	color:white;
	background-color:#c6043d;
	height:50px;
	width:100%;
	position:fixed;
	bottom:0;
	left:0;
	z-index:999;
`

const Logo = styled.div`
	position: fixed;
	background-image:url('assets/galliprant.png');
	right:0;
    height: 100vh;
	pointer-events: none;
    width: 40%;
	max-width: 550px;
	background-repeat:no-repeat;
    background-position-y: bottom;
	background-size:contain;
	z-index:9;
	bottom:50px;
	min-width:200px;
	width: 30%;
	max-width:calc(100vh/1.4);
`